import React, { useContext } from "react"
import { PageContext } from "../../context/PageContext"
import useDatoMicrocopy from "../../hooks/useDatoMicrocopy"
import DealerCard from "../molecules/DealerCard"
import DatoImage from "./DatoImage"

type DatoDealerCardProps = Queries.DatoContactDealerFragment

/**
 * Thin wrapper for DealerCard to remove Gatsby dependencies from it
 */
const DatoDealerCard = (props: DatoDealerCardProps) => {
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("contact", locale) // uses useStaticQuery()

  const labelPrefixes = {
    abbrTelephone: t("abbrTelephone", "Tel."),
    abbrPoBox: t("abbrPoBox", "P.O.Box"),
    linkShowOnMap: t("linkShowOnMap", "Show on map"),
    abbrEmail: t("abbrEmail", "Email"),
    abbrFax: t("abbrFax", "Fax"),
  }

  const contactTypeMap = {
    Retailer: t("dealerTypeRetailer", "Retailer"),
    "Service shop": t("dealerTypeServiceShop", "Service shop"),
    "Electrical supply store": t("dealerTypeElectricalSupplyStore", "Electrical supply store"),
  }
  const markerTypeMap = {
    Retailer: "retailer",
    "Service shop": "service",
    "Electrical supply store": "store",
  }

  const logo = props.logo ? (
    <DatoImage itemProp="logo" image={props.logo.image} alt={props.logo.alt || `${props.title} logo`} />
  ) : undefined

  return (
    <DealerCard
      {...props}
      markerType={props.contactType && markerTypeMap[props.contactType]}
      logo={logo}
      labelPrefixes={labelPrefixes}
      translatedDealerType={props.contactType && contactTypeMap[props.contactType]}
      googleMapsUrl={props.googleMapsUrl}
    />
  )
}

export default DatoDealerCard
